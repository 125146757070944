// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


export default app;