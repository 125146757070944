import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(model, storage, coverage) {
  return { model, storage, coverage };
}

const rows = [
  createData('iPhone 14', "128GB", "Upto 933"),
  createData('iPhone 14', "256GB/512GB", "Upto 1052"),
  createData('iPhone 14 Plus', "128GB", "TBA"),
  createData('iPhone 14 Plus', "256GB/512GB", "TBA"),
  createData('iPhone 14 Pro', "128GB", "Upto 1172"),
  createData('iPhone 14 Pro', "256GB/512GB/1TB", "Upto 1292"),
  createData('iPhone 14 Pro Max', "128GB", "Upto 1268"),
  createData('iPhone 14 Pro Max', "256GB/512GB/1TB", "Upto 1387"),
];


export default function TermAndConditions() {

  const submitHandler = (e) => {
    // console.log('submit called');
    e.preventDefault();
  }

  const [alignment, setAlignment] = React.useState('srilanka');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Helmet>
        <title> iPhone 13 Series | Terms & Conditions </title>
      </Helmet>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, 
         flexDirection: "column", alignItems: "center" }}>
          <Typography style={{ fontWeight: 700, fontSize: 30}}>
          iPhone 13 Series - Terms & Conditions
            </Typography>

            <ToggleButtonGroup
            style={{ marginTop: 30}}
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="srilanka">Srilanka</ToggleButton>
              <ToggleButton value="myanmar">Myanmar</ToggleButton>
              <ToggleButton value="brunei">Brunei</ToggleButton>
              <ToggleButton value="nepal">Nepal</ToggleButton>
            </ToggleButtonGroup>

            
            {alignment === "srilanka" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30 }}>
              Srilanka's Terms & Conditions Will Update Soon
                </Typography>
            </Grid> : null}

            {alignment === "myanmar" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30 }}>
              Myanmar's Terms & Conditions Will Update Soon
                </Typography>
            </Grid> : null}

            {alignment === "brunei" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30 }}>
              Brunei's Terms & Conditions Will Update Soon
                </Typography>
            </Grid> : null}

            {alignment === "nepal" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30 }}>
              Nepal's Terms & Conditions Will Update Soon
                </Typography>
            </Grid> : null}

          
            
        </Grid>
        

       
        
      </Grid>
    </>
  );
}
