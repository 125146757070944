import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AodIcon from '@mui/icons-material/Aod';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, writeBatch, doc, getDocs, Timestamp, deleteDoc } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update, serverTimestamp } from "firebase/database";
import { MuiFileInput } from 'mui-file-input'
import { useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import readXlsxFile from 'read-excel-file'


import { useNavigate } from 'react-router-dom'

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const rtdb = getDatabase(app);

// TODO remove, this demo shouldn't need to reset the theme.
export default function BulkUploadProductPage() {

  const [file, setFile] = React.useState(null);
  const [array, setArray] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowError, setRowError] = React.useState([]);
  const [wrongHeader, setWrongHeader] = React.useState([]);
  const [missingHeader, setMissingHeader] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fileReader = new FileReader();

  const handleOnChange = (newFile) => {
    setFile(newFile);
    setRowError([])
    setArray([]); 
    
    if (newFile && newFile.type === 'text/csv') {

      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(newFile);
    } 

    /* if (newFile && newFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      readXlsxFile(newFile).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.

         excelFileToArray(rows)
      })
    } */
  };

  const csvFileToArray = string => {
    
    const csvHeader = string.slice(0, string.indexOf("\n")).slice(0, string.indexOf("\r")).toLowerCase().replace(/\s/g,'').split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split(/\r|\n/);
   
    const header = ['resellername', 'imeino', 'purchasedate', 'country', 'city', 
    'email', 'fullname', 'homeaddress', 'nic', 'passport', 'serialno', 'created']
    
   if(csvRows[0].split(',')[0].replace(/\s/g,'').toLowerCase() === "resellername"){
    csvRows.shift();
   } 

   
    const array = csvRows.map(i => {
      
      const values = i.split(/,|\r/);

      const obj = csvHeader.reduce((object, header, index) => {

        if(header === "imeino")
        {
          object[header] = values[index];
        }
        if(header === "resellername")
        {
          object.reseller_name = values[index];
        }
        if(header === "purchasedate")
        {
          object.purchase_date = values[index];
        }
        if(header === "fullname")
        {
          object.full_name = values[index];
        }
        if(header === "homeaddress")
        {
          object.home_address = values[index];
        }
        if(header === "country")
        {
          object[header] = values[index];
        }
        if(header === "city")
        {
          object[header] = values[index];
        }
        if(header === "email")
        {
          object[header] = values[index];
        }
        if(header === "nic")
        {
          object[header] = values[index];
        }
        if(header === "passport")
        {
          object[header] = values[index];
        }
        if(header === "serialno")
        {
          object[header] = values[index];
        }
        if(header === "created")
        {
          object[header] = values[index];
        }
        return object;
      }, {});
      return obj;
    });

    
    const _wrongHeader = []
    const _correctHeader = []
    const _missingHeader = []

    csvHeader.forEach(val=>{
     
      const currentIndex = header.map(val=>val.toLowerCase()).indexOf(val.toLowerCase())
      
      if(currentIndex !== -1){
        _correctHeader.push(val)
      }
      else{
        _wrongHeader.push(val)
      }
      
    })
    
    setWrongHeader(_wrongHeader)

    header.forEach(val=>{
      const currentIndex = csvHeader.map(val=>val.toLowerCase()).indexOf(val.toLowerCase())
      if(currentIndex === -1){
        _missingHeader.push(val)
      }
    })

    setMissingHeader(_missingHeader);
    
    const _array = []
    array.forEach((item, index)=>{
      
      if(item.imeino && item.purchase_date  && item.created)
      {
        _array.push(item)
      }
    })

    const errorList = [];
    _array.forEach((item, index)=>{
      
      // // console.log(item.created)
      if(item.purchase_date){
        if(!(item.purchase_date.replace(/\s/g,'').length > 0))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }

      if(item.created){
        if(!(item.created.replace(/\s/g,'').length > 0))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }


      if(item.imeino){
        if(!(item.imeino.replace(/\s/g,'').length > 12))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }

      if(item.country){
        if(!(item.country.replace(/\s/g,'').length > 0))
        {
          errorList.push(index + 1)
        }
        else{
          item.country = item.country.replace(/\s/g,'').toLowerCase();
        }
      }
      else{
        errorList.push(index + 1)
      }
      
    })

    const uniqueErrorList = Array.from(new Set(errorList));
    
    setRowError(uniqueErrorList)
    setArray(_array);
  };

  const excelFileToArray = string => {
    const _csvHeader = string.shift();
    const csvHeader = [];
    _csvHeader.forEach((val)=>{
      
      csvHeader.push(val.toString().toLowerCase().replace(/\s/g,''))
    })

    const csvRows = string;

    const header = ['serialno', 'country', 'partno', 'shipmentdate', 'imeino']
    
    const array = csvRows.map(values => {
      
      const obj = csvHeader.reduce((object, header, index) => {
        
        if(header === "shipmentdate"){
          object[header] = moment(values[index]).format('DD/MM/YYYY');
        }
        else
        {
          object[header] = values[index];
        }
        
        
        return object;
      }, {});
      return obj;
    });

    const _wrongHeader = []
    const _correctHeader = []
    const _missingHeader = []

     csvHeader.forEach(val=>{

      const currentIndex = header.map(val=>val.toLowerCase()).indexOf(val.toLowerCase())
      
      if(currentIndex !== -1){
        _correctHeader.push(val)
      }
      else{
        _wrongHeader.push(val)
      }
      
    })
    
    setWrongHeader(_wrongHeader)

    header.forEach(val=>{
      const currentIndex = csvHeader.map(val=>val.toLowerCase()).indexOf(val.toLowerCase())
      if(currentIndex === -1){
        _missingHeader.push(val)
      }
    })

    setMissingHeader(_missingHeader);
    
    const _array = []
    array.forEach((item, index)=>{
      
      if(item.imeino && item.country && item.partno && item.serialno && item.shipmentdate)
      {
        _array.push(item)
      }
    })

    const errorList = [];
    _array.forEach((item, index)=>{
      
      if(item.serialno){
        if(!(item.serialno.replace(/\s/g,'').length > 9))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }

      if(item.country){
        if(!(item.country.replace(/\s/g,'').length > 4))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }

      if(item.partno){
        if(!(item.partno.replace(/\s/g,'').length > 6))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }

      if(item.shipmentdate){
        if(!(item.shipmentdate.replace(/\s/g,'').length > 7))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }

      if(item.imeino){
        if(!(item.imeino.toString().replace(/\s/g,'').length > 12))
        {
          errorList.push(index + 1)
        }
      }
      else{
        errorList.push(index + 1)
      }

      
      

    })

    const uniqueErrorList = Array.from(new Set(errorList));
    
    setRowError(uniqueErrorList)
    setArray(_array); 
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));


  const sleepNow = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

  const isDate = (date) => {
    return (new Date(date) !== "Invalid Date");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    let count = 0;
    const arr = [];
    get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {
        if (snapshot.exists()) {

          snapshot.forEach((doc) => {

            arr.push({
              ...doc.val(),
              id: doc.key,
            });
            
          })

          const _array = []
      
          array.forEach((value, index)=>{
            const currentIndex = arr.map(val=>val.imeino).indexOf(value.imeino)
      
            if(currentIndex === -1){
              _array.push(value)
            }
          })

          if(_array.length === 0){
            setLoading(false);
            navigate('/dashboard/device')
          }

          _array.forEach((value, index)=>{
      
            setTimeout(async ()=> {
              

              value.status = "pending_verification";
              value.file_contentType = "";
              value.file_fullPath = "";
             
              const _purchasedate = value.purchase_date.split("/");
              value.purchase_date = `${_purchasedate[2]}-${_purchasedate[1].toString().padStart(2,0)}-${_purchasedate[0].toString().padStart(2,0)}`;

              const _created = value.created.split("/");
              const _created2 = `${_created[2]}-${_created[1].toString().padStart(2,0)}-${_created[0].toString().padStart(2,0)}`;

              value.created = moment(new Date(_created2)).unix();

              const updates = {};
              updates[`device-registration/${value.imeino}`] = value;
              update(dbref(rtdb), updates);

              count += 1;
              console.log(count)
              if(_array.length === count)
              {
                setLoading(false);
                navigate('/dashboard/device')
              }

            }, index * 10);
           
            /* setTimeout(async ()=> {
              // // console.log(index)
              let countryCode = "GB";
      
              if(value.country === "mongolia")
              {
                countryCode = "MN";
              }
              else if(value.country === "brunei")
              {
                countryCode = "BN";
              }
              else if(value.country === "nepal")
              {
                countryCode = "NP";
              }
              else if(value.country === "srilanka")
              {
                countryCode = "LK";
              }
      
              const id = `${countryCode}${Timestamp.fromDate(new Date()).toMillis()}${index}`;
              value.uid = id;
              value.status = "pending_verification";
              value.file_contentType = "";
              value.file_fullPath = "";
              value.created = Timestamp.fromDate(new Date(value.created));

              if(_array.length === index + 1){
                set(dbref(rtdb, `device-registration/${val.imeino}`), value);
              } 
      
            }, index * 10); */
          }) 

          

        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      }); 

    /* const querySnapshot = await getDocs(collection(db, "device-registration-1"));
    const arr = [];

    if(!querySnapshot.empty)
    {

      querySnapshot.forEach((val) => {

        arr.push({
          ...val.data(),
          id: val.id,
        });

        
      });


      const _array = []

      const batch = writeBatch(db);
  
      array.forEach((value, index)=>{
        const currentIndex = arr.map(val=>val.imeino).indexOf(value.imeino)
  
        if(currentIndex === -1){
          _array.push(value)
        }

      })

      _array.forEach((value, index)=>{
      
      setTimeout(async ()=> {
        // // console.log(index)
        let countryCode = "GB";

        if(value.country === "mongolia")
        {
          countryCode = "MN";
        }
        else if(value.country === "brunei")
        {
          countryCode = "BN";
        }
        else if(value.country === "nepal")
        {
          countryCode = "NP";
        }
        else if(value.country === "srilanka")
        {
          countryCode = "LK";
        }

        const id = `${countryCode}${Timestamp.fromDate(new Date()).toMillis()}${index}`;
        value.id = id;
        value.status = "pending_verification";
        value.file_contentType = "";
        value.file_fullPath = "";
        value.created = Timestamp.fromDate(new Date(value.created));
        // const _createddate = value.created.split("/");
        // value.created = `${_createddate[2]}-${_createddate[1].toString().padStart(2,0)}-${_createddate[0].toString().padStart(2,0)}`;

        // const _purchasedate = value.purchase_date.split("/");
        // value.purchase_date = `${_purchasedate[2]}-${_purchasedate[1].toString().padStart(2,0)}-${_purchasedate[0].toString().padStart(2,0)}`;

        const nycRef = doc(db, "device-registration-1", id);
        batch.set(nycRef, value); 

        if(_array.length === index + 1){
          await batch.commit().then(()=>{
            setLoading(false);
            navigate('/dashboard/device')
          }) 
        } 

      }, index * 10);
    }) 

    } */
    

    
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `device_csv_template.csv`;
    link.href = "/assets/template/device_csv_template.csv";
    link.click();
  };

  if (loading) {
    return (

      <Grid style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Grid style={{
          width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
          flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container component="main">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <AodIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Bulk Upload Device Registration
        </Typography>
        <Button
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={onDownload}
          >
            Download CSV format
          </Button>
        <Box component="form" noValidate onSubmit={handleSubmit} >
          <Grid container sx={{ mt:2, display: 'flex', width: "100%",
          flexDirection: 'column',
          alignItems: 'center' }}>
            <Grid item xs={12} sm={12}>
              <MuiFileInput color='primary'

                placeholder="Import CSV/XLSX file"
                getInputText={(value) => value ? value.name : 'Import CSV/XLSX file'}
                inputProps={{ accept: ['text/CSV', "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] }}
                value={file} onChange={handleOnChange} />
            </Grid>
            
            {file ? <Grid item xs={12} sm={12}>
              <Paper sx={{ width: "90vw", maxWidth: 1000, overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 350 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                            key={"no"}
                          >
                            {"No."}
                          </TableCell>
                        {headerKeys.map((key) => 
                          {
                            let headerName = "";
                            if(key === "reseller_name"){
                              headerName = "Reseller Name";
                            }
                            else if(key === "imeino"){
                              headerName = "IMEI No";
                            }
                            else if(key === "purchase_date"){
                              headerName = "Purchase Date";
                            }
                            else if(key === "serialno"){
                              headerName = "Serial No";
                            }
                            else if(key === "full_name"){
                              headerName = "Full Name";
                            }
                            else if(key === "home_address"){
                              headerName = "Home Address";
                            }
                            else{
                              headerName = key.charAt(0).toUpperCase() + key.slice(1);;
                            }

                            return(
                              <TableCell
                                key={key}
                              >
                                {headerName}
                              </TableCell>
                            )
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      
                        {array.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => {

                          const currentIndex = rowError.map(val=>val).indexOf(index+1)

                          return (
                            <TableRow sx={{ backgroundColor: currentIndex !== -1 ? "#b21818": "white" }} role="checkbox" tabIndex={-1} key={index}>
                              <TableCell>
                                  {(index+1) + (page*rowsPerPage)}
                              </TableCell>

                              {Object.values(item).map((val) => (
                                <TableCell key={val + item.id + index }>
                                  {val}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={array.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>: null}

            <Grid item xs={12} sm={12} style={{ maxWidth: "70vw" }}>
                
                {file && rowError.length > 0 ? <Typography sx={{ color: "red", wordWrap: "break-word"}} variant="body2">
                {`No: ${rowError.join().split('|')} data incorrect`}
               </Typography> : null}
               {file && wrongHeader.length > 0 ? <Typography style={{ color: "red"}}  variant="body2">
                {`Wrong Title: ${wrongHeader.join().split('|')}`}
               </Typography> : null}
               {file && missingHeader.length > 0 ? <Typography style={{ color: "red"}}  variant="body2">
                {`Missing Title: ${missingHeader.join().split('|')}`}
                </Typography> : null}

                {file && (rowError.length > 0 || wrongHeader.length > 0 || missingHeader.length > 0)? <Typography style={{ color: "red"}}  variant="body2">
                {"Imei No, Serial No ,Part No, Country and Shipment Date field cannot be blank"}
                </Typography> : null}
          </Grid>


          </Grid>
          {file ? <Button
            type="submit"
            fullWidth
            disabled={rowError.length > 0 || wrongHeader.length > 0 || missingHeader.length > 0}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Upload
          </Button> : null}

        </Box>
      </Box>

    </Container>
  );
}