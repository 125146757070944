import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(model, storage, coverage) {
  return { model, storage, coverage };
}

const rows = [
  createData('iPhone 15 (128GB)', "850", "300"),
  createData('iPhone 15 (256GB)', "950", "300"),
  createData('iPhone 15 (512GB)', "1100", "300"),
  createData('iPhone 15 Plus (128GB)', "950", "350"),
  createData('iPhone 15 Plus (256GB)', "1100", "350"),
  createData('iPhone 15 Plus (512GB)', "1250", "350"),
  createData('iPhone 15 Pro (128GB)', "1100", "350"),
  createData('iPhone 15 Pro (256GB)', "1150", "350"),
  createData('iPhone 15 Pro (512GB)', "1400", "350"),
  createData('iPhone 15 Pro (1TB)', "1600", "350"),
  createData('iPhone 15 Pro Max (256GB)', "1300", "400"),
  createData('iPhone 15 Pro Max (512GB)', "1500", "400"),
  createData('iPhone 15 Pro Max (1TB)', "1700", "400"),
];


export default function TermAndConditions() {

  const submitHandler = (e) => {
    // console.log('submit called');
    e.preventDefault();
  }

  const [alignment, setAlignment] = React.useState('brunei');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Helmet>
        <title> iPhone 15 Series | Terms & Conditions </title>
      </Helmet>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, 
         flexDirection: "column", alignItems: "center" }}>
          <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000' }}>
          iPhone 15 Series - Terms & Conditions
            </Typography>

            <ToggleButtonGroup
            style={{ marginTop: 30}}
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="brunei">Brunei</ToggleButton>
              <ToggleButton value="nepal">Nepal</ToggleButton>
              <ToggleButton value="mongolia">Mongolia</ToggleButton>
              <ToggleButton value="srilanka">Sri Lanka</ToggleButton>
            </ToggleButtonGroup>

            {alignment === "srilanka" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000' }}>
              Extended Warranty
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              1. The additional 12 months extended warranty will only start after the 1st Year Apple Limited Warranty has lapsed.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              2. All claims will be in LKR currency.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              3. Generation Next Communications Lanka (Pvt) Ltd has the rights to reject if any of the below conditions are met:
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To consumable parts, such as batteries or protective coatings that are designed to diminish over time, unless failure has occurred due to a defect in materials or workmanship;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To cosmetic damage, including but not limited to scratches, dents and broken plastic on ports unless failure has occurred due to a defect in materials or workmanship;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by use with a third party component or product that does not meet the Apple Product’s specifications (Apple Product specifications are available at www.apple.com under the technical specifications for each product and also available in stores);
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by accident, abuse, misuse, fire, liquid contact, earthquake or other external cause;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by operating the Apple Product outside Apple’s published guidelines;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by service (including upgrades and expansions) performed by anyone who is not a representative of Apple or an Apple Authorized Service Provider (“AASP”);
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To an Apple Product that has been modified to alter functionality or capability without the written permission of Apple;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To defects caused by normal wear and tear or otherwise due to the normal aging of the Apple Product;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - If any serial number has been removed or defaced from the Apple Product; or
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - If Apple receives information from relevant public authorities that the product has been stolen or if you are unable to deactivate passcode-enabled or other security measures designed to prevent unauthorized access to the Apple Product, and you cannot prove in any way that you are the authorized user of the product
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              4. All claims must be presented with proof of purchase.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              5. Generation Next Communications Lanka (Pvt) Ltd will not be liable for any loss of data.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              6. Generation Next Communications Lanka (Pvt) Ltd has rights to make any amendments or changes.
              </Typography>
              

                <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 50 }}>
              One Time Screen Replacement
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                1. Claim valid only for the Display and is valid only within 1 year from the date of purchase (or) activation date whichever is earlier.
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                2. The Screen Replacement will be covered for only one time, within 1 year from the date of purchase (or) activation date whichever is earlier.
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                3. Other defects must be rectified first if found before proceeding with display replacement (issues like liquid damage, body displacement, rear casing crack will not be covered).
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                4. A charge of Rs. 28,000 should be paid by user as display replacement fee.
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                5. Should customer decide not to proceed with the claim and/or repairs, after diagnosis to ascertain claim eligibility is completed, a labour service charge of Rs. 5000 will be billed to customer for work done on the phone.
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                6. Any other additional costs incurred during the replacement of the screen due to force majeure must be borne by the customer.
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                7. A period of 90 Days functional guarantee will be provided for replacement display.
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                8. Approval for repair will take a minimum of 2 working days from the date of receipt of the device to the Apple Authorized Service Provider (“AASP”).
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                9. The display replacement shall be subject to parts availability. AASP shall not be responsible for its non-availability due to force majeure.
                </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 14, color: '#000000', marginTop: 20 }}>
              The claim limits
              </Typography>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>DESCRIPTION/MODEL</StyledTableCell>
                      <StyledTableCell align="right">EW REPAIR LIMIT (USD)</StyledTableCell>
                      <StyledTableCell align="right">SCREEN CRACK REPAIR LIMIT (USD)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.model}>
                        <StyledTableCell component="th" scope="row">
                          {row.model}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.storage}</StyledTableCell>
                        <StyledTableCell align="right">{row.coverage}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography style={{ fontWeight: "bold", fontSize: 14, color: '#000000', marginTop: 20 }}>
              All iPhones 15 family must be registered within 14days of purchase if not ASP (Authorised Service Provider) have the rights to reject unless otherwise stated. The registration is available only during the offer period. 
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              ** These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.**
              </Typography>

            </Grid> : null}

            {alignment === "brunei" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000' }}>
              One Time Screen Replacement
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             1. Claim valid only for the Display and is valid only within 1 year from the date of purchase (or) activation date whichever is earlier.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             2. The Screen Replacement will be covered for only one time, within 1 year from the date of purchase (or) activation date whichever is earlier.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             3. Other defects must be rectified first if found before proceeding with display replacement (issues like liquid damage, body displacement, rear casing crack will not be covered).
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             4. A charge of B$69 should be paid by user as display replacement fee.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             5. Should customer decide not to proceed with the claim and/or repairs, after diagnosis to ascertain claim eligibility is completed, a labour service charge of B$80 (or USD58) will be billed to customer for work done on the phone.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             6. Any other additional costs incurred during the replacement of the screen due to force majeure must be borne by the customer.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             7. A period of 90 Days functional guarantee will be provided for replacement display.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             8. Approval for repair will take a minimum of 2 working days from the date of receipt of the device to the Apple Authorized Service Provider (“AASP”).
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             9. The display replacement shall be subject to parts availability. AASP shall not be responsible for its non-availability due to force majeure.
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 50 }}>
              Extended Warranty
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             1. The additional 12 months extended warranty will only start after the 1st Year Apple Limited Warranty has lapsed.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             2. The 2nd year extended warranty would be applicable with the following limits. Consumer shall bear the cost if the repairs; service charges; and/or replacement unit cost exceed the maximum claim limit.
              </Typography>
              <Typography style={{ fontWeight: 700, fontSize: 14, color: '#000000', marginTop: 20 }}>
              The claim limit will be as such:
              </Typography>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>DESCRIPTION/MODEL</StyledTableCell>
                      <StyledTableCell align="right">EW REPAIR LIMIT (USD)</StyledTableCell>
                      <StyledTableCell align="right">SCREEN CRACK REPAIR LIMIT (USD)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.model}>
                        <StyledTableCell component="th" scope="row">
                          {row.model}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.storage}</StyledTableCell>
                        <StyledTableCell align="right">{row.coverage}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             3. All claims will be in USD currency.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             4. AV Electronics has the rights to reject if any of the below conditions are met:
              </Typography>
              
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To consumable parts, such as batteries or protective coatings that are designed to diminish over time, unless failure has occurred due to a defect in materials or workmanship;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To cosmetic damage, including but not limited to scratches, dents and broken plastic on ports unless failure has occurred due to a defect in materials or workmanship;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by use with a third party component or product that does not meet the Apple Product’s specifications (Apple Product specifications are available at www.apple.com under the technical specifications for each product and also available in stores);
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by accident, abuse, misuse, fire, liquid contact, earthquake or other external cause;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by operating the Apple Product outside Apple’s published guidelines;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by service (including upgrades and expansions) performed by anyone who is not a representative of Apple or an Apple Authorized Service Provider (“AASP”);
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To an Apple Product that has been modified to alter functionality or capability without the written permission of Apple;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To defects caused by normal wear and tear or otherwise due to the normal aging of the Apple Product;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - If any serial number has been removed or defaced from the Apple Product; or
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - If Apple receives information from relevant public authorities that the product has been stolen or if you are unable to deactivate passcode-enabled or other security measures designed to prevent unauthorized access to the Apple Product, and you cannot prove in any way that you are the authorized user of the product
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             5. All claims must be presented with proof of purchase.
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
            6. AV Electronics will not be liable for any loss of data.
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
            7. AV Electronics has rights to make any amendments or changes.
              </Typography>

              <Typography style={{ fontWeight: "bold", fontSize: 14, color: '#000000', marginTop: 20 }}>
              All iPhones 15 family must be registered within 14days of purchase if not ASP (Authorised Service Provider) have the rights to reject unless otherwise stated
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              ** These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.**
              </Typography>

            </Grid> : null}


            {alignment === "nepal" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000' }}>
              One Time Screen Replacement
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             1. Claim valid only for the Display and is valid only within 1 year from the date of purchase (or) activation date whichever is earlier.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             2. The Screen Replacement will be covered for only one time, within 1 year from the date of purchase (or) activation date whichever is earlier.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             3. Other defects must be rectified first if found before proceeding with display replacement (issues like liquid damage, body displacement, rear casing crack will not be covered).
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             4. A charge of Rs3500 should be paid by user as display replacement fee.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             5. Should customer decide not to proceed with the claim and/or repairs, after diagnosis to ascertain claim eligibility is completed, a labour service charge of Rs1500 will be billed to customer for work done on the phone.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             6. Any other additional costs incurred during the replacement of the screen due to force majeure must be borne by the customer.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             7. A period of 90 Days functional guarantee will be provided for replacement display.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             8. Approval for repair will take a minimum of 2 working days from the date of receipt of the device to the Apple Authorized Service Provider (“AASP”).
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             9. The display replacement shall be subject to parts availability. AASP shall not be responsible for its non-availability due to force majeure.
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 50 }}>
              Extended Warranty
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             1. The additional 12 months extended warranty will only start after the 1st Year Apple Limited Warranty has lapsed.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             2. All claims will be in Rs currency.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             3. Generation Next Communication Pvt Ltd & Evolution Trading Pvt. Ltd has the rights to reject if any of the below conditions are met:
              </Typography>
              
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To consumable parts, such as batteries or protective coatings that are designed to diminish over time, unless failure has occurred due to a defect in materials or workmanship;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To cosmetic damage, including but not limited to scratches, dents and broken plastic on ports unless failure has occurred due to a defect in materials or workmanship;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by use with a third party component or product that does not meet the Apple Product’s specifications (Apple Product specifications are available at www.apple.com under the technical specifications for each product and also available in stores);
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by accident, abuse, misuse, fire, liquid contact, earthquake or other external cause;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by operating the Apple Product outside Apple’s published guidelines;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by service (including upgrades and expansions) performed by anyone who is not a representative of Apple or an Apple Authorized Service Provider (“AASP”);
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To an Apple Product that has been modified to alter functionality or capability without the written permission of Apple;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To defects caused by normal wear and tear or otherwise due to the normal aging of the Apple Product;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - If any serial number has been removed or defaced from the Apple Product; or
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - If Apple receives information from relevant public authorities that the product has been stolen or if you are unable to deactivate passcode-enabled or other security measures designed to prevent unauthorized access to the Apple Product, and you cannot prove in any way that you are the authorized user of the product
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             5. All claims must be presented with proof of purchase.
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
            6. Generation Next Communication Pvt Ltd & Evolution Trading Pvt. Ltd will not be liable for any loss of data.
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
            7. Generation Next Communication Pvt Ltd & Evolution Trading Pvt. Ltd has rights to make any amendments or changes.
              </Typography>

              <Typography style={{ fontWeight: "bold", fontSize: 14, color: '#000000', marginTop: 20 }}>
              All iPhones 15 family must be registered within 14days of purchase if not ASP (Authorised Service Provider) have the rights to reject unless otherwise stated
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              ** These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.**
              </Typography>

            </Grid> : null}

            {alignment === "mongolia" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000' }}>
              Нэг удаагийн хагарсан, цуурсан дэлгэц засварлах, солих нөхцөл:
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             1. Гар утсыг худалдан авсан өдрөөс хойш эхний 12 сарын хугацаанд хагарсан, цуурсан гэмтэлтэй дэлгэцийг зөвхөн нэг удаа үнэ төлбөргүй солино. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             2. Шингэн нэвтэрснээс үүдэлтэй гэмтэл, эдэлгээний явцад үүссэн сэв зураас, элэгдэл нь төлбөргүй солих үйлчилгээнд хамаарахгүй. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             3. Нэг удаагийн дэлгэцийн гэмтлийг засварлахад дэлгэцийн үнийг авахгүй ба хэрэглэгч засварын ажлын хөлс болох 50,000 төгрөгийг төлнө.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             4. Хэрэглэгч уг үйлчилгээнд хамрагдахдаа гар утасны гэмтэлтэй дэлгэц буюу хуучин дэлгэц заавал байх шаардлагатай. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             5. Уг үйлчилгээг нэг удаа авсан хэрэглэгч дахин дэлгэцээ солиулах, засварлуулах тохиолдолд төлбөрийг хэрэглэгч бүрэн хариуцна.  
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 50 }}>
              Нэмэлт буюу 12 сараар сунгасан баталгааны нөхцөл:  
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             1. Үйлдвэрлэгчээс олгосон баталгаат хугацаа дууссанаас хойш 12 сарын хугацаанд баталгаат засварын нэмэлт нөхцөл эхлэх бөгөөд хэрэглэгчийн үйл ажиллагаанаас үл шалтгаалсан, механик бус эвдрэл гэмтлийг Хавсралт №1-т заасан үнийн дүнгийн хязгаарлалтын хүрээнд үнэ төлбөргүй засварлана. 
             </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              Хавсралт №1
              </Typography>

              <TableContainer style={{ marginTop: 20 }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Загвар / Багтаамж</StyledTableCell>
                      <StyledTableCell align="right">Нэмэлт 12 сараар сунгасан баталгаат засварын дээд хягзаар (Ам. Доллар - USD)</StyledTableCell>
                      <StyledTableCell align="right">Нэг удаагийн дэлгэц солих дээд хязгаар (Ам. Доллар - USD)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.model}>
                        <StyledTableCell component="th" scope="row">
                          {row.model}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.storage}</StyledTableCell>
                        <StyledTableCell align="right">{row.coverage}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              2.	Уг үйлчилгээний нөхцөлийн дагуу авсан нийт засвар үйлчилгээний үнийн дүн Хавсралт №1 тогтоосон хязгаарлалтад хүрсэн тохиолдолд нэмэлт буюу 12 сараар сунгасан баталгааны хугацаа дуусаагүй байсан ч засвар үйлчилгээг үзүүлэх боломжгүй байна. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              3.	Нэмэлт 12 сараар сунгасан баталгааны нөхцөлд дараах нөхцөлүүд хамаарахгүй. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 10, paddingLeft: 15 }}>
              a.	Бүх төрлийн механик буюу хэрэглэгчийн үйл ажиллагаанаас шалтгаалсан эвдрэл, гэмтэл 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              b.	Дэлгэцийн механик болон механик бус, бүх төрлийн эвдрэл, гэмтэл.   
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              c.	Батарейн цэнэглэлтийн багтаамж буюу “Battery Health”
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 50 }}>
              3. Бусад нөхцөл
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              1.	Нэг удаагийн дэлгэц засварлах, солих нөхцөл болон нэмэлт буюу 12 сараар сунгасан баталгааны нөхцөлийн дагуу хийсэн засвар үйлчилгээнд Apple-н Албан ёсны баталгаат засвар (AASP - Apple Authorized Service Provider) 30 хоногийн баталгаа олгоно. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              2.	Нэг удаагийн дэлгэц засварлах, солих болон нэмэлт 12 сараар сунгасан баталгааны нөхцөлд хамаарахгүй бусад гэмтэл доголдлын засвар үйлчилгээний төлбөрийг хэрэглэгч хариуцна. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              3.	Дээрх засвар үйлчилгээ нь сэлбэг, эд ангиудын бэлэн байдлаас хамаарах бөгөөд Apple-н Албан Ёсны Баталгаат засвар (AASP - Apple Authorized Service Provider)-с хамаарахгүй үйл ажиллагаа буюу давагдашгүй хүчин зүйлс, гадаад улсын нөхцөл байдлын улмаас сэлбэг нийлүүлэх, засварлах боломжгүй болсон тохиолдолд Apple-н Албан ёсны баталгаат засвар (AASP - Apple Authorized Service Provider)-н төв хариуцлага хүлээхгүй.
              </Typography>

             
              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 50 }}>
              БАТАЛГААТ ЗАСВАРЫН ХАВСРАЛТ НӨХЦӨЛ
                </Typography>

                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
                1.	Хэрэглэгч “Баталгаат Засварын Төв”-д борлуулагчаас олгосон хүчин төгөлдөр баталгаат засварын хуудсыг авчирсан тохиолдолд үйлчилгээг үзүүлнэ. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              2.	Хэрэглэгчийн санаатай болон санамсаргүй үйлдлээс болж ашиглах заавар зөрчигдөж, орчин тойрны өмч хөрөнгөнд шууд болон шууд бусаар учирсан хохирлыг Мобиком Корпораци ХХК хариуцахгүй. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              3.	Хэрэглэгч ‘’Баталгаат засварын төв’’-д хандахдаа гар утсан дахь хувийн мэдээллээ урьдчилан хадгалж авч үлдсэн байх шаардлагатай
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              4.	Энэхүү нөхцөл нь Монгол, Англи хэл дээр байх бөгөөд өгүүлбэрийн утга дээр эргэлзээ үүсвэл Монгол хэл дээрх утгыг баримтлана.  
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 50 }}>
              4. Баталгааны бусад нөхцөлд дараах нөхцлүүд хамаарахгүй. 
                </Typography>

                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
                4.	Гар утас, нэмэлт хэрэгслийг хэрэглэгчид хүлээлгэн өгсний дараа үүссэн механик гэмтэл (нүдэнд ил харагдахуйцаар хагарсан, хайлсан, цуурсан, сэв суусан, тасарсан, шалбарсан, мурийлт тахийлт үүссэн, ус чийг болон шингэн биет орсон, цахилгаан тэжээлийн гэмтэл)
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              5.	Гэмтэл нь вирус түүнтэй адилтгах программаас үүдсэн, мэргэжлийн бус аргаар программ хангамж уншуулсан, программ хангамжийн үндсэн тохиргоог өөрчлөх эсвэл дахин хуурамч программ хангамж суулгасан.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              6.	Баталгаат хугацаандаа Мобиком Корпораци ХХК дахь Apple-н албан ёсны “Баталгаат Засварын төв” -с өөр албан ёсны бус засварын төв, хувь хүнээр засуулсан, оношлуулах байдлаар задарсан.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              7.	Ахуйн буюу хувийн хэрэгцээнд зориулсан барааг үйлдвэрийн болон зориулалтын бусаар ашигласан, ашиглах заавар зөрчсөн.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              8.	Гар утас, нэмэлт хэрэгсэлд байрлуулсан баталгаат засварын лацыг хөдөлгөсөн, оролдсон болон сериал дугаар зөрсөн. системээс хасагдсан, эсвэл элэгдэж харагдахгүй болсон буюу эрх бүхий бүтээгдэхүүн гэдгийн нотлох боломжгүй болсон. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              9.	Гар утас, нэмэлт хэрэгслийн ашиглалтын зөвлөмжийг зөрчсөнөөс үүссэн болон элэгдэж хуучирсантай холбоотой эвдрэл гэмтэл.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              10.	Гар утас, нэмэлт хэрэгслийн баталгаат засварын хугацаа дууссан.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              11.	Хэвийн элэгдэл буюу сэв зураас, хонхорхой зэрэг хэрэглээний элэгдлүүд.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15  }}>
              12.	Хулгайлагдсан, алдагдсан байж болзошгүй ангилалд багтаж утасны нууц код, бусад хамгаалалтууд идэвхэжсэн.
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30  }}>
              a.	Гар утас, нэмэлт хэрэгслийг хэрэглэгчид хүлээлгэн өгсний дараа үүссэн механик гэмтэл (нүдэнд ил харагдахуйцаар хагарсан, хайлсан, цуурсан, сэв суусан, тасарсан, шалбарсан, мурийлт үүссэн, ус чийг болон шингэн биет орсон, цахилгаан тэжээлийн гэмтэл).
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30  }}>
              b.	Гэмтэл нь вирус, түүнтэй адилтгах програмаас үүдсэн, мэргэжлийн бус аргаар програм хангамж уншуулсан, програм хангамжийн үндсэн тохиргоог өөрчлөх эсвэл дахин хуурамч програм хангамж суулгасан. тасарсан, шалбарсан, мурийлт үүссэн, ус чийг болон шингэн биет орсон, цахилгаан тэжээлийн гэмтэл).
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30  }}>
              c.	Баталгаат хугацаандаа Apple-н Албан ёсны баталгаат засварын төвөөс өөр албан ёсны бус засварын төв, хувь хүнээр засуулсан, оношлуулах байдлаар задарсан.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30  }}>
              d.	Ахуйн болон хувийн хэрэгцээнд зориулсан барааг үйлдвэрийн болон зориулалтын бусаар ашигласан, ашиглах заавар зөрчсөн.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30  }}>
              e.	Гар утас, нэмэлт хэрэгсэлд байрлуулсан баталгаат засварын лацыг хөдөлгөсөн, оролдсон болон сериал дугаар зөрсөн. системээс хасагдсан, эсвэл элэгдэж харагдахгүй болсон буюу эрх бүхий бүтээгдэхүүн гэдгийн нотлох боломжгүй болсон. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30  }}>
              f.	Гар утас, нэмэлт хэрэгслийн ашиглалтын зөвлөмжийг зөрчсөнөөс үүссэн болон элэгдэж хуучирсантай холбоотой эвдрэл гэмтэл.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30  }}>
              g.	Гар утас, төхөөрөмж, нэмэлт хэрэгслийн баталгаат засварын хугацаа дууссан.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30  }}>
              h.	Хэвийн элэгдэл буюу сэв, зураас, хонхорхой зэрэг хэрэглээний элэгдлүүд.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30  }}>
              i.	Хулгайлагдсан, алдагдсан байж болзошгүй ангилалд багтаж утасны нууц код, бусад хамгаалалтууд идэвхжсэн. 
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 50 }}>
             English: 
                </Typography>

                <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000' }}>
              One Time Screen Replacement
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              a.	Claim valid only for the screen and is valid only within 12 months from the date of purchase (or) activation date, whichever is earlier. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              b.	Other defects must be rectified first if found before proceeding with screen replacement (issues like liquid damage, body displacement, rear casing crack will not be covered. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              c.	A charge of 50,000 ₮ (MNT) should be paid by consumers as a screen replacement fee. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              d.	The consumer must have their own damaged and/or old mobile phone screen when they get a service screen replacement at AASP (Apple Authorized Service Provider).
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              e.	If the customer who has got the service once has to repair the screen again, the consumer is fully responsible for the payment.
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 20 }}>
              2.	Extended Warranty
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              a.	Extended warranty for 12 months after the expiration of the Apple limited warranty will begin, and non-mechanical damage not caused by consumer activity will be repaired free of charge within the limits of the amount specified in Table No1.  
              </Typography>

              <TableContainer style={{ marginTop: 20 }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Model / Capacity</StyledTableCell>
                      <StyledTableCell align="right">Extended Warranty limit (USD)</StyledTableCell>
                      <StyledTableCell align="right">Display crack repair limit (USD)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.model}>
                        <StyledTableCell component="th" scope="row">
                          {row.model}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.storage}</StyledTableCell>
                        <StyledTableCell align="right">{row.coverage}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              b.	If the total amount of repair received under the terms of extended warranty service reaches the limit specified in Table No 1, repair cannot be provided even if the warranty period extended by 12 months has not expired.
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 , paddingLeft: 15}}>
              c.	The following terms & conditions do not apply to the extended warranty by 12 months:
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30 }}>
              i.	Any type of mechanical/liquid/electrical or consumer-related damage. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30 }}>
              ii.	All kinds of mechanical and non-mechanical damage to the screen. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 30 }}>
              iii.	To consumable parts, such as “Battery Health” or protective coatings that are designed to diminish over time, unless failure has occurred due to a defect in materials or workmanship. 
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 20 }}>
              3.	Other terms & conditions for repair service
                </Typography>

                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                a.	A period of 30 days functional guarantee will be provided for screen replacement and other repairs. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              b.	The consumer shall pay for all kinds of damage and defects excluding “One-Time Screen Replacement" and "Extended Warranty by 12 months" terms & condition. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              c.	Above all kind repairs shall be subject to spare parts availability. ASP (Apple Authorized Service Provider) of Mobicom Corporation LLC shall not be responsible for its non-availability due to force majeure, beyond its reasonable control or any other international situation and supplier.
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 20 }}>
              4.	Appendix terms & conditions of warranty period
                </Typography>

                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                a.	All claims must be presented with a warranty card of authorized resellers. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              b.	AASP of Mobicom Corporation LLC shall not be liable for direct or indirect damage to environmental property due to a consumer's intentional or accidental violation of instructions.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              c.	All terms & conditions are made in Mongolian and English in two identical. In the event of any discrepancies, the Mongolian version shall prevail.
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 20 }}>
              5.	AASP (Apple Authorized Service Provider) of Mobicom Corporation LLC has the right to reject if any of the below conditions are met:
                </Typography>

                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
                a.	Mechanical damage that occurs after handing over mobile phones and accessories to the consumers (visible cracks, melts, scratches, cuts, bends, moisture and liquid contacts, power supply damage)
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              b.	The defect was caused by a virus, virus-like software, unauthorized software download, changing the factory software settings or re-installation of fake software.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              c.	During the all warranty period, an unofficial repair service other than the Apple Authorized Service Provider at Mobicom Corporation LLC, personal repairman repaired or disassembled for diagnosis.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              d.	Use of the iPhone15 series is intended for personal, if used for industrial or other purposes, violation of instructions for use. 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              e.	The warranty seal on the mobile phone and accessories has been moved or tried and the serial number/IMIE has been lost, has been removed from the registration system, is no longer visible or cannot be proven to be an authorized product.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              f.	Damage caused by violation of the instructions for use of the mobile phone or accessories or due to wear and tear and normal aging.  
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              g.	All warranty periods for mobile phones and accessories have expired.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              h.	Normal wear and tear from use such as scratches and dents.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              i.	Consumers cannot deactivate the passcode, Apple ID or other protections are enabled, potentially stolen or lost.
              </Typography>


            </Grid> : null}

          
            
        </Grid>

       
        
      </Grid>
    </>
  );
}
