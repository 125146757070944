import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(model, storage, coverage) {
  return { model, storage, coverage };
}

const rows = [
  createData('iPhone 14', "128GB", "Upto 933"),
  createData('iPhone 14', "256GB/512GB", "Upto 1052"),
  createData('iPhone 14 Plus', "128GB", "TBA"),
  createData('iPhone 14 Plus', "256GB/512GB", "TBA"),
  createData('iPhone 14 Pro', "128GB", "Upto 1172"),
  createData('iPhone 14 Pro', "256GB/512GB/1TB", "Upto 1292"),
  createData('iPhone 14 Pro Max', "128GB", "Upto 1268"),
  createData('iPhone 14 Pro Max', "256GB/512GB/1TB", "Upto 1387"),
];


export default function TermAndConditions() {

  const submitHandler = (e) => {
    // console.log('submit called');
    e.preventDefault();
  }

  const [alignment, setAlignment] = React.useState('srilanka');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Helmet>
        <title> iPhone 14 Series | Terms & Conditions </title>
      </Helmet>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, 
         flexDirection: "column", alignItems: "center" }}>
          <Typography style={{ fontWeight: 700, fontSize: 30 }}>
          iPhone 14 Series - Terms & Conditions
            </Typography>

            <ToggleButtonGroup
            style={{ marginTop: 30}}
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="srilanka">Srilanka</ToggleButton>
              <ToggleButton value="myanmar">Myanmar</ToggleButton>
              <ToggleButton value="brunei">Brunei</ToggleButton>
              <ToggleButton value="nepal">Nepal</ToggleButton>
            </ToggleButtonGroup>

            {alignment === "srilanka" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30 }}>
              One Time Screen Replacement
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             1. Claim valid only for the Display and is valid only within 1 year from the date of purchase (or) activation date whichever is earlier.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             2. The Screen Replacement will be covered for only one time, within 1 year from the date of purchase (or) activation date whichever is earlier.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             3. Other defects must be rectified first if found before proceeding with display replacement (issues like liquid damage, body displacement, rear casing crack will not be covered).
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             4. A charge of Rs. 18,000/- should be paid by user as display replacement fee.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             5. Any other additional costs incurred during the replacement of the screen due to force majeure must be borne by the customer.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             6. A period of 90 Days functional guarantee will be provided for replacement display.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             7. Approval for repair will take a minimum of 2 working days from the date of receipt of the device to the Apple Authorized Service Provider (“AASP”).
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             8. The display replacement shall be subject to parts availability. AASP shall not be responsible for its non-availability due to force majeure.
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000', marginTop: 50 }}>
              Extended Warranty
                </Typography>
                <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             1. The additional 12 months extended warranty will only start after the 1st Year Apple Limited Warranty has lapsed.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             2. The 2nd year extended warranty would be applicable with the following limits. Consumer shall bear the cost if the repairs; service charges; and/or replacement unit cost exceed the maximum claim limit.
              </Typography>
              <Typography style={{ fontWeight: 700, fontSize: 14, color: '#000000', marginTop: 20 }}>
              The claim limit will be as such:
              </Typography>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>DESCRIPTION/MODEL</StyledTableCell>
                      <StyledTableCell align="right">STORAGE</StyledTableCell>
                      <StyledTableCell align="right">COVERAGE LIMIT (USD)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.model}>
                        <StyledTableCell component="th" scope="row">
                          {row.model}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.storage}</StyledTableCell>
                        <StyledTableCell align="right">{row.coverage}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             3. All claims will be in USD currency.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             4. Generation Next Communications Lanka Pvt Ltd has the rights to reject if any of the below conditions are met:
              </Typography>
              
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To consumable parts, such as batteries or protective coatings that are designed to diminish over time, unless failure has occurred due to a defect in materials or workmanship;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To cosmetic damage, including but not limited to scratches, dents and broken plastic on ports unless failure has occurred due to a defect in materials or workmanship;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by use with a third party component or product that does not meet the Apple Product’s specifications (Apple Product specifications are available at www.apple.com under the technical specifications for each product and also available in stores);
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by accident, abuse, misuse, fire, liquid contact, earthquake or other external cause;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by operating the Apple Product outside Apple’s published guidelines;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To damage caused by service (including upgrades and expansions) performed by anyone who is not a representative of Apple or an Apple Authorized Service Provider (“AASP”);
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To an Apple Product that has been modified to alter functionality or capability without the written permission of Apple;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - To defects caused by normal wear and tear or otherwise due to the normal aging of the Apple Product;
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - If any serial number has been removed or defaced from the Apple Product; or
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              - If Apple receives information from relevant public authorities that the product has been stolen or if you are unable to deactivate passcode-enabled or other security measures designed to prevent unauthorized access to the Apple Product, and you cannot prove in any way that you are the authorized user of the product
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
             5. All claims must be presented with proof of purchase.
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
            6. Generation Next Communications Lanka Pvt will not be liable for any loss of data.
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
            7. Generation Next Communications Lanka Pvt has rights to make any amendments or changes.
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              ** These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.**
              </Typography>

            </Grid> : null}

            {alignment === "myanmar" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000' }}>
              Myanmar's Terms & Conditions Will Update Soon
                </Typography>
            </Grid> : null}

            {alignment === "brunei" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000' }}>
              Brunei's Terms & Conditions Will Update Soon
                </Typography>
            </Grid> : null}

            {alignment === "nepal" ? <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000' }}>
              Nepal's Terms & Conditions Will Update Soon
                </Typography>
            </Grid> : null}

          
            
        </Grid>
       
        
      </Grid>
    </>
  );
}
