import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(2400)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: sample(['356706921621886', '356706921621812', '356706921624124']),
  partNo: sample(['EW', 'SC', 'EW & SC']),
  status: sample(['13 Jul 2023', '14 Sept 2023', '1 Aug 2023']),
  category: sample([
    'IPHONE 13 GREEN 512GB A2633-ITP',
    'IPHONE 13 GREEN 256GB A2633-ITP',
    'IPHONE 13 GREEN 128GB A2633-ITP',
    'IPHONE 13 STARLIGHT 128GB'
  ]),
}));

export default users;
