// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'product',
    path: '/dashboard/product',
    icon: icon('ic_product'),
  },
  {
    title: 'Iphone 15',
    path: '/dashboard/imei',
    icon: icon('ic_device'),
    submenu: [{
      title: 'IMEI',
      path: '/dashboard/imei',
      icon: icon('ic_imei'),
    },
    {
      title: 'Iphone 15 Device',
      path: '/dashboard/device',
      icon: icon('ic_device'),
    },
    {
      title: 'Purchased IMEI',
      path: '/dashboard/purchased_imei',
      icon: icon('ic_imei'),
    }]
  },
  {
    title: 'Iphone 16',
    path: '/dashboard/iphone16/imei',
    icon: icon('ic_device'),
    submenu: [{
      title: 'IMEI',
      path: '/dashboard/iphone16/imei',
      icon: icon('ic_imei'),
    },
    {
      title: 'Iphone 16 Device',
      path: '/dashboard/iphone16/device',
      icon: icon('ic_device'),
    },
    {
      title: 'Purchased IMEI',
      path: '/dashboard/iphone16/purchased_imei',
      icon: icon('ic_imei'),
    }]
  },
  {
    title: 'Macbook',
    path: '/dashboard/mac/serial_number',
    icon: icon('ic_device'),
    submenu: [{
      title: 'Serial Number',
      path: '/dashboard/mac/serial_number',
      icon: icon('ic_imei'),
    },
    {
      title: 'Mac Device',
      path: '/dashboard/mac/device',
      icon: icon('ic_device'),
    },
    {
      title: 'Purchased Serial Number',
      path: '/dashboard/mac/purchased_serial_number',
      icon: icon('ic_imei'),
    }]
  },
  {
    title: 'IPad',
    path: '/dashboard/ipad/imei',
    icon: icon('ic_device'),
    submenu: [{
      title: 'IMEI',
      path: '/dashboard/ipad/imei',
      icon: icon('ic_imei'),
    },
    {
      title: 'IPad Device',
      path: '/dashboard/ipad/device',
      icon: icon('ic_device'),
    },
    {
      title: 'Purchased IMEI',
      path: '/dashboard/ipad/purchased_imei',
      icon: icon('ic_imei'),
    }]
  },
  {
    title: 'Search All Device',
    path: '/dashboard/search_device',
    icon: icon('ic_device'),
  },
  {
    title: 'Download Data',
    path: '/dashboard/download_data',
    icon: icon('ic_download'),
  }
];

export default navConfig;
