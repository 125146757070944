import { Navigate, useRoutes } from 'react-router-dom';

import { Grid } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import Frontend from './layouts/frontend';
//

// import MacSrilankaRegistration from './pages/mac/SrilankaRegistration';
import MacMyanmarRegistration from './pages/mac/MyanmarRegistration';

// import IpadSrilankaRegistration from './pages/ipad/SrilankaRegistration';
import IpadMyanmarRegistration from './pages/ipad/MyanmarRegistration';

import SearchRegistration from './pages/SearchRegistration';
import SrilankaRegistration from './pages/SrilankaRegistration';
import BruneiRegistration from './pages/BruneiRegistration';
import NepalRegistration from './pages/NepalRegistration';


import TermAndConditions from './pages/TermAndConditions';
import TermAndConditionsIphone15 from './pages/TermAndConditionsIphone15';
import TermAndConditionsIphone14 from './pages/TermAndConditionsIphone14';
import TermAndConditionsIphone13 from './pages/TermAndConditionsIphone13';
import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';
import MacHomePage from './pages/MacHomePage';
import IpadHomePage from './pages/IpadHomePage';

import ImeiPage from './pages/ImeiPage';
import MacSerialNumberPage from './pages/MacSerialNumberPage';
import PurchasedImeiPage from './pages/PurchasedImeiPage';
import DevicePage from './pages/DevicePage';
import MacPage from './pages/MacPage';

import AllDevicePage from './pages/AllDevicePage';
import DownloadDataPage from './pages/DownloadDataPage';
import SearchDevicePage from './pages/SearchDevicePage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import ProductPage from './pages/ProductPage';
import DashboardAppPage from './pages/DashboardAppPage';

// iphone16
import Iphone16ImeiPage from './pages/iphone16/ImeiPage';
import Iphone16PurchasedImeiPage from './pages/iphone16/PurchasedImeiPage';
import Iphone16DevicePage from './pages/iphone16/DevicePage';
import Iphone16NewPurchasedImei from './pages/iphone16/NewPurchasedImei';
import Iphone16NewImeiPage from './pages/iphone16/NewImeiPage';
import Iphone16BulkUploadImeiPage from './pages/iphone16/BulkUploadImeiPage';
import Iphone16BulkUploadDevicePage from './pages/iphone16/BulkUploadDevicePage';

import NewUserPage from './pages/NewUserPage';
import NewSuperAdminPage from './pages/NewSuperAdminPage';
import NewPurchasedImei from './pages/NewPurchasedImei';
import NewProductPage from './pages/NewProductPage';
import NewImeiPage from './pages/NewImeiPage';
import NewDeviceRegistrationPage from './pages/NewDeviceRegistrationPage';

import BulkUploadProductPage from './pages/BulkUploadProductPage';
import BulkUploadImeiPage from './pages/BulkUploadImeiPage';
import BulkUploadSerialNumberPage from './pages/BulkUploadSerialNumberPage';
import BulkUploadDevicePage from './pages/BulkUploadDevicePage';
import BulkUploadMacDevicePage from './pages/BulkUploadMacDevicePage';

import { UserAuth } from './context/AuthContext'
// ----------------------------------------------------------------------

export default function Router() {

  const { user } = UserAuth()

  const routes = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'mac/home', element: <MacHomePage /> },
        // { path: 'ipad/home', element: <IpadHomePage /> },
        { path: 'search-extended-warranty-registration', element: <SearchRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        { path: 'srilanka-extended-warranty-registration', element: <SrilankaRegistration />},
        { path: 'brunei-extended-warranty-registration', element: <BruneiRegistration />},
        { path: 'nepal-extended-warranty-registration', element: <NepalRegistration />},
        { path: 'mac/myanmar-extended-warranty-registration', element: <MacMyanmarRegistration />},
        // { path: 'ipad/myanmar-extended-warranty-registration', element: <IpadMyanmarRegistration />},
        { path: 'terms-and-conditions-iphone15-series', element: <TermAndConditionsIphone15 />},
        { path: 'terms-and-conditions-iphone14-series', element: <TermAndConditionsIphone14 />},
        { path: 'terms-and-conditions-iphone13-series', element: <TermAndConditionsIphone13 />},
        
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <Navigate to="/login" replace />,
    },
    {
      path: '/dashboard/*',
      element: <Navigate to="/login" replace />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  const routesAdmin = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'mac/home', element: <MacHomePage /> },
        // { path: 'ipad/home', element: <IpadHomePage /> },
        { path: 'search-extended-warranty-registration', element: <SearchRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        { path: 'srilanka-extended-warranty-registration', element: <SrilankaRegistration />},
        { path: 'brunei-extended-warranty-registration', element: <BruneiRegistration />},
        { path: 'nepal-extended-warranty-registration', element: <NepalRegistration />},
        { path: 'mac/myanmar-extended-warranty-registration', element: <MacMyanmarRegistration />},
        // { path: 'ipad/myanmar-extended-warranty-registration', element: <IpadMyanmarRegistration />},
        { path: 'terms-and-conditions-iphone15-series', element: <TermAndConditionsIphone15 />},
        { path: 'terms-and-conditions-iphone14-series', element: <TermAndConditionsIphone14 />},
        { path: 'terms-and-conditions-iphone13-series', element: <TermAndConditionsIphone13 />},
        
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'new_user', element: <NewUserPage /> },
        { path: 'product', element: <ProductPage /> },
        { path: 'new_product', element: <NewProductPage /> },
        { path: 'bulk_upload_product', element: <BulkUploadProductPage /> },
        

        { path: 'imei', element: <ImeiPage /> },
        { path: 'new_imei', element: <NewImeiPage /> },
        { path: 'bulk_upload_imei', element: <BulkUploadImeiPage /> },
        { path: 'device', element: <DevicePage /> },
        { path: 'bulk_upload_device', element: <BulkUploadDevicePage /> },
        { path: 'purchased_imei', element: <PurchasedImeiPage /> },
        { path: 'new_purchased_imei', element: <NewPurchasedImei /> },

        { path: 'iphone16/imei', element: <Iphone16ImeiPage /> },
        { path: 'iphone16/new_imei', element: <Iphone16NewImeiPage /> },
        { path: 'iphone16/bulk_upload_imei', element: <Iphone16BulkUploadImeiPage /> },
        { path: 'iphone16/device', element: <Iphone16DevicePage /> },
        { path: 'iphone16/bulk_upload_device', element: <Iphone16BulkUploadDevicePage /> },
        { path: 'iphone16/purchased_imei', element: <Iphone16PurchasedImeiPage /> },
        { path: 'iphone16/new_purchased_imei', element: <Iphone16NewPurchasedImei /> },

        { path: 'mac/serial_number', element: <MacSerialNumberPage /> },
        { path: 'mac/new_serial_number', element: <Iphone16NewImeiPage /> },
        { path: 'mac/bulk_upload_serial_number', element: <Iphone16BulkUploadImeiPage /> },
        { path: 'mac/device', element: <MacPage /> },
        { path: 'mac/bulk_upload_device', element: <BulkUploadMacDevicePage /> },
        { path: 'mac/purchased_serial_number', element: <Iphone16PurchasedImeiPage /> },
        { path: 'mac/new_purchased_serial_number', element: <Iphone16NewPurchasedImei /> },

        { path: 'ipad/imei', element: <Iphone16ImeiPage /> },
        { path: 'ipad/new_imei', element: <Iphone16NewImeiPage /> },
        { path: 'ipad/bulk_upload_imei', element: <Iphone16BulkUploadImeiPage /> },
        { path: 'ipad/device', element: <Iphone16DevicePage /> },
        { path: 'ipad/bulk_upload_device', element: <Iphone16BulkUploadDevicePage /> },
        { path: 'ipad/purchased_imei', element: <Iphone16PurchasedImeiPage /> },
        { path: 'ipad/new_purchased_imei', element: <Iphone16NewPurchasedImei /> },

        { path: 'bulk_upload_mac_serial_number', element: <BulkUploadSerialNumberPage /> },
        { path: 'mac_serial_number', element: <MacSerialNumberPage /> },
        { path: 'mac', element: <MacPage /> },
        { path: 'new_device', element: <NewDeviceRegistrationPage /> },
        { path: 'search_device', element: <SearchDevicePage /> },
        { path: 'all_device', element: <AllDevicePage /> },
        { path: 'download_data', element: <DownloadDataPage /> },
        
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);


  const routesEditor = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'mac/home', element: <MacHomePage /> },
        // { path: 'ipad/home', element: <IpadHomePage /> },
        { path: 'search-extended-warranty-registration', element: <SearchRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        { path: 'srilanka-extended-warranty-registration', element: <SrilankaRegistration />},
        { path: 'brunei-extended-warranty-registration', element: <BruneiRegistration />},
        { path: 'nepal-extended-warranty-registration', element: <NepalRegistration />},
        { path: 'mac/myanmar-extended-warranty-registration', element: <MacMyanmarRegistration />},
        // { path: 'ipad/myanmar-extended-warranty-registration', element: <IpadMyanmarRegistration />},
        { path: 'terms-and-conditions-iphone15-series', element: <TermAndConditionsIphone15 />},
        { path: 'terms-and-conditions-iphone14-series', element: <TermAndConditionsIphone14 />},
        { path: 'terms-and-conditions-iphone13-series', element: <TermAndConditionsIphone13 />},
        
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/device" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/device" />, index: true },
        { path: 'device', element: <DevicePage /> },
        { path: 'search_device', element: <SearchDevicePage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  const routesManager = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'mac/home', element: <MacHomePage /> },
        // { path: 'ipad/home', element: <IpadHomePage /> },
        { path: 'search-extended-warranty-registration', element: <SearchRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        { path: 'srilanka-extended-warranty-registration', element: <SrilankaRegistration />},
        { path: 'brunei-extended-warranty-registration', element: <BruneiRegistration />},
        { path: 'nepal-extended-warranty-registration', element: <NepalRegistration />},
        { path: 'mac/myanmar-extended-warranty-registration', element: <MacMyanmarRegistration />},
        // { path: 'ipad/myanmar-extended-warranty-registration', element: <IpadMyanmarRegistration />},
        { path: 'terms-and-conditions-iphone15-series', element: <TermAndConditionsIphone15 />},
        { path: 'terms-and-conditions-iphone14-series', element: <TermAndConditionsIphone14 />},
        { path: 'terms-and-conditions-iphone13-series', element: <TermAndConditionsIphone13 />},
        
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/device" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/device" />, index: true },
        { path: 'user', element: <UserPage /> },
        { path: 'new_user', element: <NewUserPage /> },
        { path: 'device', element: <DevicePage /> },
        { path: 'search_device', element: <SearchDevicePage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  let _userRole = "";

  if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
    _userRole = JSON.parse(user.reloadUserInfo.customAttributes).role;
  }

  if(user === "none"){
    return (
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>
        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
        flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  if(user && _userRole === "super_admin")
  {
    return routesAdmin
  }

  if(user && _userRole === "editor")
  {
    return routesEditor
  }

  if(user && _userRole === "manager")
  {
    return routesManager
  }

  return routes;
}
