import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Stack, Typography, Grid, Button } from '@mui/material';

// ----------------------------------------------------------------------

export default function HomePage() {

  return (
    <>
      <Helmet>
        <title> Home | Genxt Services </title>
      </Helmet>

      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column", paddingBottom: 80  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column", alignItems: "center" }}>
          <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#cecece' }}>
            REGISTER NOW FOR Extended & Screen Replacement
            Warranty
            </Typography>

            <Grid style={{ display: "flex", justifyContent: "center", padding: 15, paddingBottom: 0, marginTop: 20 }}>
          <img src="/assets/images/banners/apple_logo_white.png" alt='Apple Logo' width={200} />
          </Grid>
          <Typography align="center" style={{ fontWeight: 700, fontSize: 36, color: '#cecece', marginTop: 15 }}>
          So Strong. So Light. So Pro.
          </Typography>
          
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "black" }}>
          <Grid style={{ backgroundColor: "black", padding: 15, paddingBottom: 0 }}>
            <img src="/assets/images/banners/banner-1.jpg" alt='banner1' width={800}/>
          </Grid>
        </Grid>
        

        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60,paddingBottom: 80,
          flexDirection: "column",  alignItems: "center", backgroundColor: "black" }}>
           <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#cecece' }}>
            REGISTER NOW FOR Extended & Screen Replacement
            Warranty
            </Typography>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "white", flexDirection: "column" }}>
          <Grid style={{ display: "flex", justifyContent: "center", backgroundColor: "white", padding: 15, paddingBottom: 0, marginTop: 20 }}>
          <img src="/assets/images/banners/apple_logo.png" alt='Apple Logo' width={200} />
          </Grid>
          <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#151515', marginTop: 20 }}>
          New camera. New design. Newphoria.
          </Typography>
          <Grid style={{ display: "flex", justifyContent: "center", backgroundColor: "white", padding: 15 }}>
            <img src="/assets/images/banners/banner2-1.jpg" alt='banner2' width={800} />
          </Grid>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60, paddingBottom: 80,
          flexDirection: "column",  alignItems: "center", backgroundColor: "black" }}>
          <Typography style={{ fontWeight: 700, fontSize: 36, color: '#cecece' }}>
          Search Your Extended Warranty Registration
          </Typography>
          <Typography style={{ fontWeight: 100, fontSize: 14, color: '#cecece' }}>
          Now you can search your Extended Warranty Registration at anytime.
Search your registration now!!
          </Typography>
          <Button variant="contained" style={{ marginTop: 20 }} href={"/search-extended-warranty-registration"}   disableElevation>
          Search Registration
          </Button>
        </Grid>


        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60,
          flexDirection: "column",  alignItems: "center", backgroundColor: "#161617" }}>
          <Typography style={{ fontWeight: 700, fontSize: 36, color: '#cecece' }}>
          Register Your Device
          </Typography>
          <Typography style={{ fontWeight: 100, fontSize: 14, color: '#cecece' }}>
          Extended Warranty Registration
Register your device according to where you purchased your device
          </Typography>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "#161617" }}>
          <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="center" sx={{ my: 0 }}>
            <Grid style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <img src="/assets/images/country/srilanka.png" alt='srilanka_flag' width={220} style={{ padding: 20}} />
              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#cecece' }}>
              SRI LANKA
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 11, color: '#cecece' }}>
              iPhone 15 Family Registration
              </Typography>
              <Button variant="contained" href={"/srilanka-extended-warranty-registration"} style={{ marginTop: 20 }} disableElevation>
              Register Now
              </Button>
            </Grid>
            {/* <Grid style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <img src="/assets/images/country/myanmar.png" alt='myanmar_flag' width={220} style={{ padding: 20}} />
              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#cecece' }}>
              MYANMAR
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 11, color: '#cecece' }}>
              iPhone 15 Family Registration
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 11, color: '#cecece' }}>
              (Starting From: Check with your retailers)
              </Typography>
              <Button variant="contained" href={"#"} style={{ marginTop: 20 }} disableElevation>
              Register Now
              </Button>
  </Grid> */}
            <Grid style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <img src="/assets/images/country/brunei.png" alt='brunei_flag' width={220} style={{ padding: 20}} />
              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#cecece' }}>
              BRUNEI
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 11, color: '#cecece' }}>
              iPhone 15 Family Registration
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 11, color: '#cecece' }}>
              (Starting From: 4th October 2023)
              </Typography>
              <Button variant="contained" href={"/brunei-extended-warranty-registration"} style={{ marginTop: 20 }} disableElevation>
              Register Now
              </Button>
            </Grid>
            <Grid style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <img src="/assets/images/country/nepal.png" alt='nepal_flag' width={220} style={{ padding: 20}} />
              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#cecece' }}>
              NEPAL
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 11, color: '#cecece' }}>
              iPhone 15 Family Registration
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 11, color: '#cecece' }}>
              (Starting From: 13th October 2023)
              </Typography>
              <Button variant="contained" href={"/nepal-extended-warranty-registration"} style={{ marginTop: 20 }} disableElevation>
              Register Now
              </Button>
            </Grid>
            <Grid style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <img src="/assets/images/country/mongolia.png" alt='nepal_flag' width={220} style={{ padding: 20}} />
              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#cecece' }}>
              MONGOLIA
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 11, color: '#cecece' }}>
              iPhone 15 Family Registration
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 11, color: '#cecece' }}>
              (Starting From: 20th October 2023)
              </Typography>
              <Button variant="contained" href={"/terms-and-conditions"} style={{ marginTop: 20 }} disableElevation>
              Terms & Conditions
              </Button>
            </Grid>
          </Stack>
        </Grid>

        
      </Grid>
    </>
  );
}
