import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Stack, Typography, Grid, Button } from '@mui/material';

// ----------------------------------------------------------------------

export default function HomePage() {

  return (
    <>
      <Helmet>
        <title> Home | Genxt Services </title>
      </Helmet>

      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column", paddingBottom: 80  }}>


        <Grid style={{ backgroundColor: 'White', width: "100%", display: "flex",  flexDirection: "column", alignItems: "center" }}>
          <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column", alignItems: "center" }}>
            <Grid container>
              <Grid item xs={12} lg={9}>
              <Typography align="left" style={{ fontWeight: 700, fontSize: 70, color: '#1d1d1f' }}>
            Mac
              </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                  <Typography align="left" style={{ marginTop: 17, fontWeight: 700, fontSize: 24, color: '#1d1d1f' }}>
                If you can dream it,
                </Typography>
                <Typography align="left" style={{ fontWeight: 700, fontSize: 24, color: '#1d1d1f' }}>
                Mac can do it.
                </Typography>
              </Grid>
            </Grid>
            
            
          </Grid>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "#f9f9f9" }}>
          <Grid style={{ backgroundColor: "#f9f9f9", padding: 15, paddingBottom: 0 }}>
            <img src="/assets/images/banners/banner-2.jpg" alt='banner1' width={"100%"}/>
          </Grid>
        </Grid>
        

        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60,paddingBottom: 80,
          flexDirection: "column",  alignItems: "center", backgroundColor: "black" }}>
           <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#cecece' }}>
            REGISTER NOW FOR Extended & Screen Replacement
            Warranty
            </Typography>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "white", flexDirection: "column" }}>
          <Typography align="center" style={{ fontWeight: 700, fontSize: 30, color: '#151515', marginTop: 20 }}>
          Easy to use. Easy to love.
          </Typography>
          <Grid style={{ display: "flex", justifyContent: "center", backgroundColor: "white", padding: 15 }}>
            <img src="/assets/images/banners/banner2-2.jpg" alt='banner2' width={800} />
          </Grid>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60, paddingBottom: 80,
          flexDirection: "column",  alignItems: "center", backgroundColor: "black" }}>
          <Typography style={{ fontWeight: 700, fontSize: 36, color: '#cecece' }}>
          Search Your Extended Warranty Registration
          </Typography>
          <Typography style={{ fontWeight: 100, fontSize: 14, color: '#cecece' }}>
          Now you can search your Extended Warranty Registration at anytime.
Search your registration now!!
          </Typography>
          <Button variant="contained" style={{ marginTop: 20 }} href={"/search-extended-warranty-registration"}   disableElevation>
          Search Registration
          </Button>
        </Grid>


        <Grid style={{ width: "100%", display: "flex", padding: 25,  paddingTop: 60,
          flexDirection: "column",  alignItems: "center", backgroundColor: "#161617" }}>
          <Typography style={{ fontWeight: 700, fontSize: 36, color: '#cecece' }}>
          Register Your Device
          </Typography>
          <Typography style={{ fontWeight: 100, fontSize: 14, color: '#cecece' }}>
          Extended Warranty Registration
Register your device according to where you purchased your device
          </Typography>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "#161617" }}>
          <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="center" sx={{ my: 0 }}>
            <Grid style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <img src="/assets/images/country/myanmar.png" alt='myanmar_flag' width={220} style={{ padding: 20}} />
              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#cecece' }}>
              MYANMAR
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 11, color: '#cecece' }}>
              Mac Registration
              </Typography>
              <Button variant="contained" href={"/mac/myanmar-extended-warranty-registration"} style={{ marginTop: 20 }} disableElevation>
              Register Now
              </Button>
            </Grid>
          </Stack>
        </Grid>

        
      </Grid>
    </>
  );
}
